// noinspection GraphQLUnresolvedReference

import gql from 'graphql-tag';

const QUERY = gql`
  query ContactMoment(
    $first: Int,
    $page: Int,
    $filter: ContactMomentStatusFilter,
    $filters: [DataGridFilterInput],
    $sorters: [DataGridSorterInput]
  ) {
    list: contactMoments(
      first: $first,
      page: $page,
      filter: $filter,
      filters: $filters,
      sorters: $sorters
    ) {
      data {
        data
      }
      dataGridInfo {
        columns {
          name
          label
        }
        filters {
          name
          applied
          data
          metadata
          __typename
        }
        sorters {
          name
          applied
          direction
        }
        metadata
      }
      paginatorInfo {
        count
        currentPage
        firstItem
        hasMorePages
        lastItem
        lastPage
        perPage
        total
      }
    }
  }
`;

export default QUERY;
